<template>
  <VCard :width="width" class="mx-auto">
    <VCardTitle>
      <VRow>
        <VCol>
          <VImg
            src="/logo_l.png"
            srcset="/logo_l@2x.png"
            width="300"
            height="82"
            class="mx-auto"
            alt="CallReach"
            contain
          />
        </VCol>
      </VRow>
    </VCardTitle>
    <div class="text-center text-h5 red--text">{{ deployTargetName }}</div>
    <slot />
  </VCard>
</template>

<script>
import settings from '@/settings';

export default {
  name: 'LogoCard',

  props: {
    width: {
      type: [Number, String],
      default: 600,
    },
  },

  data() {
    return {
      deployTargetName: settings.deployTargetName,
    };
  },
};
</script>
