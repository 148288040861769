






































import Vue from 'vue';
import { required, maxLength, email } from 'vuelidate/lib/validators';
import LogoCard from '@/components/specific/LogoCard.vue';
import { MESSAGE_SEND_EMAIL_DONE } from '@/resources/defines';
import ServiceFactory from '@/services/ui/ServiceFactory';
import { UICommonMapper } from '@/store/modules/ui/common';
import { UIPasswordResetMapper } from '@/store/modules/ui/passwordReset';

const AuthService = ServiceFactory.get('auth');

export default Vue.extend({
  name: 'PasswordResetReceptionPage',
  components: { LogoCard },

  data(): {
    loading: boolean;
    requestParams: {
      email: string;
    };
  } {
    return {
      loading: false,
      requestParams: {
        email: '',
      },
    };
  },

  methods: {
    ...UIPasswordResetMapper.mapActions(['setPasswordResetMailAddress']),
    ...UICommonMapper.mapActions(['setMessage', 'setErrorMessage']),
    async requestPasswordReset() {
      const self = this;

      self.$v.$touch();

      if (self.$v.$invalid) {
        self.$$log.debug(self.$v);
        return;
      }

      self.loading = true;

      try {
        await AuthService.requestPasswordRest({
          email: self.requestParams.email,
        });

        self.setMessage({
          color: 'success',
          text: MESSAGE_SEND_EMAIL_DONE,
        });

        self.loading = false;

        // パスワード再設定受付メールアドレスを設定
        self.setPasswordResetMailAddress({ email: self.requestParams.email });

        self.$router.push('/password_reset');
      } catch (error) {
        self.loading = false;
        self.$$log.error(error);
        self.setErrorMessage({ text: error.message });
      }
    },
  },

  validations() {
    return {
      requestParams: {
        email: {
          // エラーメッセージを表示したい順序で定義する
          /* eslint-disable vue/sort-keys */
          required,
          email,
          maxLength: maxLength(128),
          /* eslint-enable vue/sort-keys */
        },
      },
    };
  },
});
